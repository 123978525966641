import { useContext, useState } from "react";
import { paramsValidate } from "../utils/functions";
import { console_log } from "../utils/console_log";
import { SwalAlert } from "../components/alert/SwalAlert";
import useApiEffect from "./../hooks/useApiEffect";
import { GetService } from "./services/requests-service";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingContext } from "react-router-loading";
import { useAuth } from "../utils/AuthContext";

const useGetData = ({
  route,
  params,
  onSuccess,
  notLoadData,
  All,
  loadMore,
}) => {
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [errors, setErrors] = useState(null);
  const location = useLocation();
  const loadingContext = useContext(LoadingContext); // loading On Routing Full-back changes & until data is returned from server
  const urlParams = new URLSearchParams(location.search); // Query Params included in url
  const paramsObject = Object.fromEntries(urlParams.entries());
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    pageNo: 1,
  });
  const getApi = async ({ params, loadMore }) => {
    try {
      setLoading(true);
      loadMore && setPagination((prev) => ({ ...prev, pageNo: 1, page: 1 }));
      const validatedParams = paramsValidate(
        !params ? paramsObject : { ...paramsObject, ...params }
      );
      const res = await GetService({ route, params: validatedParams });
      // console.log({ route, res })
      setData(res.data?.result);
      setCount(res.data.count);
      const pageNo = Math.ceil(res?.data?.count / pagination?.limit);
      setPagination((prev) => ({ ...prev, pageNo: pageNo + 1 }));
      loadingContext.done();
      setLoading(false);
      const updateData = ({ newData }) => {
        // console_log({ data })
        setData(newData.result);
      };
      if (onSuccess) {
        // called To set Data when Edit Item on specific separate Route called with param for ex. Route of edit form by _id
        onSuccess(res, updateData); // Why ? Because when data of item is to much and have many calculatons so made it in a separate route individually
      }
      return res;
    } catch (error) {
      console_log("error ===>", { error });
      setErrors(error);
      loadingContext.done();
      setLoading(false);
      const status = error?.response?.status;
      if (status === 401 || status === 403) {
        localStorage.removeItem("sona3_info_token");
        setErrors({ error: error?.response?.data?.error });
        SwalAlert({ text: t("your session has expired"), status: false });
      } else {
        setErrors({ error: error?.response?.data?.error });
        SwalAlert({
          text: error?.response?.data?.error,
          status: error?.response?.data?.success,
        });
      }
      return { error: error?.response?.data?.error };
    }
  };
  useApiEffect(() => {
    if (!notLoadData && isAuthenticated) {
      getApi({ route, params, All });
    }
  }, [urlParams.toString(), isAuthenticated]);
  //=================================
  //loading more Data //
  //=================================
  const loadMoreData = async () => {
    if (
      pagination?.pageNo >= 1 &&
      pagination?.page <= pagination?.pageNo &&
      !loadingMoreData
    ) {
      setLoadingMoreData(true);
      try {
        // const filterParams = !params ? pagination : { page: pagination?.page + 1, limit: pagination?.limit/*, ...params*/ }
        // const validateParams = filterParams;
        // const apiParams = new URLSearchParams(validateParams);
        const filterParams = !params
          ? pagination
          : { page: pagination?.page + 1, limit: pagination?.limit, ...params };
        const apiParams = paramsValidate(filterParams);
        const res = await GetService({ route, params: apiParams });
        setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
        setData((prev) => {
          const newResults = res?.data?.result || [];
          const existingIds = prev.map((obj) => obj._id);
          // Filter out duplicates based on '_id'
          const uniqueNewResults = newResults.filter(
            (obj) => !existingIds.includes(obj._id)
          );
          return [...prev, ...uniqueNewResults];
        });
        // console.log({ res }, "loadMoreData");
      } catch (error) {
        console_log({ error });
      }
      setLoadingMoreData(false);
    }
  };
  return {
    loading: loading,
    data: data,
    count: count,
    errors: errors,
    setData: setData,
    getData: getApi,
    loadMoreData,
    loadingMoreData,
    setPagination,
  };
};
export default useGetData;
