import Axios from './axios';

const GetService = async ({ route, params, }) => {
  return await Axios.get(route, { params: params })
}

const PostService = async ({ route, params, body = {} }) => {
  const response = await Axios.post(route, body, { params: params });
  // console.log("Post", response);
  return response;
}

const DeleteService = async ({ route, params }) => {
  const response = await Axios.delete(route, { params , })
  return response;
}
const UpdateService = async ({ route, params, body = {} }) => {
  const response = await Axios.put(route, body, { params , })
  return response;
}

export { PostService, DeleteService , GetService, UpdateService };