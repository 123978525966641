
import { BrowserRouter as Router } from 'react-router-dom'
import Layout from './components/Layout/Layout';
import App_Routes from './App_Routes';
import { useTranslation } from 'react-i18next';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import ScrollToTop from './components/scroll-top/ScrollTop';
import { ConfigProvider } from 'antd';
import usePostData from './api/usePostData';
import { useAuth } from './utils/AuthContext';
import { jwtDecode } from 'jwt-decode';

AOS.init({ once: false, offset: 20, disableMutationObserver: true, });
AOS.refresh();
function App() {
const  {postData} = usePostData()
const { setIsAuthenticated, setTokenData } = useAuth();
useEffect(() => {
  const handleScroll = () => { AOS.init({ once: false, offset: 20, disableMutationObserver: true, }); AOS.refresh(); };
  window.addEventListener('scroll', handleScroll);
  return () => { window.removeEventListener('scroll', handleScroll); };
}, []); 
useEffect(() => {
  const getToken = async()=>{
    setIsAuthenticated(false)
    setTokenData({})
    localStorage.removeItem('sona3_info_token')
    // if(!localStorage.getItem("sona3_info_token")){
      const res = await postData({route:'customer/guest'}) 
      // console.log(res);
      if(!res.error){
        localStorage.setItem("sona3_info_token",res?.token)
        const decoded = jwtDecode(res?.token);
        setTokenData(decoded)
        setIsAuthenticated(true)
      }
    // }
  }
  getToken()
}, []); 



  const {i18n} = useTranslation()
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#0073e0', // Primary color for the theme
      },
      components: {
        // Button: {
        //   colorPrimary: '#006ec6', // Primary color for buttons
        //   algorithm: true, // Enable algorithm (optional)
        // },
        Radio: {
          colorPrimary: '#88050d',  // Changes the dot color
          colorPrimaryHover: 'red',  // Changes the hover state
        },
        Input: {
          colorPrimary: '#a9a9a9', // Primary color for inputs
          algorithm: true, // Enable algorithm (optional)
        },
        Select: {
          background: '#f0f2f5', // Background color for Select component
          border: '1px solid #d9d9d9', // Border color for Select component
          colorPrimary: '#ff8c00', // Primary color for Select component
          algorithm: true, // Enable algorithm (optional)
        },
      },
    }}
  >
    <div className="App" style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl', fontFamily:i18n.language === 'en' ? "JosefinSans" : "GE_SS_light"}}>
     <Router>
          <Layout>
              <ScrollToTop/>
              <App_Routes />
          </Layout>
     </Router>
    </div>
    </ConfigProvider>
  );
}

export default App;
