import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import style from './styles/partners.module.css';
import { Image } from 'antd';
import '@splidejs/splide/css';
import './styles/splide.css';
import { partners } from '../../../DummyData/Data';
import SplitterHeader from '../../../common/SplitterHeader';
export default function Partners() {
  const { i18n } = useTranslation();
  const splideRef = useRef(null);
  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);

  return (
    <div className={style.section}>
      <SplitterHeader text="Our Partners" />
      <Splide
        aria-label="partners_splide"
        id="partners_splide"
        ref={splideRef}
        options={{
          start: 0,
          rewind: false,
          width: '100%',
          gap: '2rem',
          perPage: 4,
          direction: i18n.language === 'en' ? 'ltr' : 'rtl',
          lazyLoad: 'nearby',
          // drag   : 'free',
          focus  : 'center',
          //type:'loop',
          breakpoints: {
            880: {
              perPage: 3,
            },
            750: {
              perPage: 3,
              gap: '0.2rem',
            },
            500: {
              gap: '1rem',
            },
            430: {
              gap: '2rem',
            },
          },
        }}
      >
        {partners.map((item, i) => (
          <SplideSlide>
            <Image className={style.img} src={item} preview={false} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
