import { useState } from 'react';
import { PostService } from './services/requests-service';
import { useTranslation } from 'react-i18next';
import { SwalAlert } from '../components/alert/SwalAlert';

const usePostData = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);
  const {t} = useTranslation()
  // === post data and update loading,error and data state
  const postData = async ({ route, data, successMsg , params }) => {
    // console.log(route, data, successMsg, params);
  
    try {
      setLoading(true)
      const res = await PostService({ route, body: data , params });
      setLoading(false);
      setErrors(null);
      setData(res.data);
      successMsg &&  SwalAlert({ text: t(successMsg), status: true })
      return res.data;
    } catch (error) {
      setLoading(false);
      setErrors(error?.response?.data.error);
      const status = error?.response?.status;
      if (status === 401 || status === 403) {
        if(route !== "login"){
          setErrors({ error: error?.response?.data?.error });
          SwalAlert({ text: t('your session has expired'), status: false })
          localStorage.removeItem("sona3_info_token")
        }
      } else {
        SwalAlert({ text: t('error'), status: false })
        setErrors({ error: error?.response?.data?.error });
      }

      return {
         error: error?.response?.data.error,
         errorsData: error?.response?.data
        };
    }
  };

  return {
    loading: loading,
    res: data,
    errors: errors,
    postData: postData,
    setData: setData,
    setErrors,
  };
};

export default usePostData;
