import React, { useEffect, useState } from "react";
import Header1 from "../../../common/Header1";
import { Col, Row, Spin, Skeleton } from "antd";
import { Description, Name } from "../../../utils/functions";
import style from "../style/style.module.css";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CustomButton from "../../../common/CustomButton";
import { useNavigate } from "react-router-dom";
import { LineIcon, PocketIcon } from "react-share";

export default function SubCategories({ category, loadingCategory }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState(category);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    if (AOS) {
      AOS.init({ duration: 1500 });
      setTimeout(() => {
        AOS.refreshHard(); // Refresh AOS to re-trigger animations
      }, 0); // Delay ensures AOS has time to initialize before refreshing
    }
  }, []);

  useEffect(() => {
    setCategories(category);
    const initialLoadingStates = {};
    category.length > 0 &&
      category.forEach((cat, catIndex) => {
        cat?.subCategories.forEach((_, subIndex) => {
          initialLoadingStates[`${catIndex}-${subIndex}`] = true;
        });
      });
    setLoadingStates(initialLoadingStates);
    setTimeout(() => {
      AOS.refreshHard(); // Refresh AOS to re-trigger animations
    }, 0);
  }, [category]);

  const handleImageLoad = (catIndex, subIndex) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [`${catIndex}-${subIndex}`]: false,
    }));
  };

  const renderSkeleton = () => (
    <div className={style.category_content}>
      <div className={style.skeleton_center}>
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className={style.sub_cards}>
        <Row gutter={[16, 16]}>
          {Array.from({ length: 8 }).map((_, index) => (
            <Col
              xs={12} // 2 columns on small screens (0-576px)
              sm={8} // 3 columns on medium screens (576px-768px)
              lg={6} // 4 columns on large screens (768px and up)
              key={index}
            >
              <Skeleton.Image style={{ width: "100%", height: 200 }} />
              <Skeleton active title={false} paragraph={{ rows: 2 }} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );

  return (
    <div className={style.subCategories_container}>
      {loadingCategory ? (
        renderSkeleton()
      ) : (
        <>
          {categories?.map((item, catIndex) => (
            <div className={style.category_content} key={catIndex}>
              <Header1
                text={item?.[Name(i18n.language)]}
                style={{ textAlign: "center" }}
              />
              <p className={style.main_description}>
                {item?.[Description(i18n.language)]}
              </p>
              <CustomButton
                onClick={() => {
                  navigate("/comingsoon");
                  // window.open(`https://shop.sona3.ae/all-stores?category=${item._id}`, '_blank');
                }}
                id={style.Customer_Btn}
                text={"Go Shopping"}
              />

              <div className={style.sub_cards}>
                <Row gutter={[16, 16]}>
                  {item?.subCategories
                    .filter((subItem) => subItem.isActive)
                    .map((subItem, subIndex) => (
                      <Col
                        xs={12} // 2 columns on small screens (0-576px)
                        sm={8} // 3 columns on medium screens (576px-768px)
                        lg={6} // 4 columns on large screens (768px and up)
                        key={subIndex}
                      >
                        <div
                          onClick={() => {
                            navigate("/comingsoon");
                            // window.open(`https://shop.sona3.ae/all-stores?category=${subItem._id}`, '_blank');
                          }}
                          className={style.sub_card}
                          data-aos="flip-left"
                          data-aos-duration="1500"
                        >
                          {loadingStates[`${catIndex}-${subIndex}`] && (
                            // <Spin
                            //   style={{
                            //     position: 'absolute',
                            //     top: '50%',
                            //     left: '50%',
                            //     transform: 'translate(-50%, -50%)',
                            //   }}
                            // />
                            // <p>Loading...</p>
                            <img
                              src="placeholder-image1.jpg"
                              alt="coming soon"
                              style={{
                                // border: "2px solid red",
                                marginBottom: "-150px",
                                maxWidth: "100%"
                              }}
                            />
                            // <LineIcon />
                          )}
                          <LazyLoadImage
                            className={style.sub_image}
                            src={subItem?.image?.Location}
                            alt={subItem?.[Name(i18n.language)]}
                            effect="blur"
                            afterLoad={() =>
                              handleImageLoad(catIndex, subIndex)
                            }
                          />
                          <div className={style.sub_details}>
                            <div className={style.sub_name}>
                              {subItem?.[Name(i18n.language)]}
                            </div>
                            <p className={style.sub_description}>
                              {subItem?.[Description(i18n.language)]}
                            </p>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
