import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, Button, Image } from "antd";
import style from "./styles/suggession.module.css";
import { useTranslation } from "react-i18next";
import { cursor, suggesst_persons } from "../../../assets";
import { SwalAlert } from "../../../components/alert/SwalAlert";
import { suggessionFormSchema } from "../../../utils/Validations";
import usePostData from "../../../api/usePostData";
const { TextArea } = Input;

const SuggessionForm = () => {
  const complaintsUrl =
    "https://sona3-test-3918329fd6a7.herokuapp.com/api/v1/informative/complaints/create";
  const { postData, res, loading, errors } = usePostData();
  const { t, i18n } = useTranslation();
  const initialValues = {
    name: "",
    email: "",
    body: "",
    creationDate: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const finalData = { ...values, creationDate: new Date().toISOString() };

    const res = await postData({
      route: complaintsUrl,
      data: finalData,
      successMsg: "Your suggestion has been sent successfully",
    });

    if (res?.error) {
      console.error("Submission error:", res.error);
    } else {
      console.log("Submission success:", res);
      resetForm();
    }

    setSubmitting(false);
  };

  return (
    <div className={style.container}>
      <div className={style.bg_section}>
        <div
          className={style.section_h1}
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          {t("let's talk")}
        </div>
        <div
          className={style.section_h2}
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
        >
          {t(
            "We want visitors to write suggestions and opinions so that we can constantly develop"
          )}
        </div>
        <Image
          className={style.section_image}
          src={suggesst_persons}
          preview={false}
          data-aos="zoom-in"
          data-aos-duration="3000"
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={suggessionFormSchema(t)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={style.form}>
            <div className={style.form_content}>
              <div className={style.form_header}>
                {t(
                  "Send us your suggestions and opinions on a regular basis to help us continuously develop"
                )}
                <img src={cursor} alt="cursor" className={style.cursor} />
              </div>
              <div className={style.field_div}>
                <label htmlFor="name">{t("Name")}</label>
                <Field
                  className={style.field}
                  name="name"
                  as={Input}
                  placeholder={t("Enter your name")}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  style={{ color: "red", marginTop: "5px" }}
                />
              </div>

              <div className={style.field_div}>
                <label htmlFor="email">{t("Email")}</label>
                <Field
                  className={style.field}
                  name="email"
                  as={Input}
                  placeholder={t("Enter your email")}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "red", marginTop: "5px" }}
                />
              </div>

              <div className={style.field_div}>
                <label htmlFor="body">{t("Your Suggestion")}</label>
                <Field
                  style={{ resize: "none" }}
                  className={style.field}
                  name="body"
                  as={TextArea}
                  placeholder={t("Enter your suggestion")}
                  rows={4}
                />
                <ErrorMessage
                  name="body"
                  component="div"
                  style={{ color: "red", marginTop: "5px" }}
                />
              </div>
            </div>

            <div className={style.field_div}>
              <Button
                className={style.submit_btn}
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? t("Submitting...") : t("Send")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SuggessionForm;
