import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { convertToArabicNumeral } from "../../../utils/functions";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useNavigate } from "react-router-dom";
import { Packages } from "../../../DummyData/Data";
import Header3 from "./../../../common/Header3";
import CustomButton from "./../../../common/CustomButton";
import { arrow_vector, wave_down, wave_up } from "../../../assets";
import SplitterHeader from "../../../common/SplitterHeader";
import style from "./styles/pricing.module.css";
import { FaPlus } from "react-icons/fa6";
import { Button, Radio } from "antd";
import "@splidejs/splide/css";
import "./styles/splide.css";
import { useAuth } from "../../../utils/AuthContext";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { SELLER_PORTAL } from "../../../env";
export default function Pricing() {
  const { t, i18n } = useTranslation();
  const [Type, setType] = useState("annually");
  const [Sort, setSort] = useState("products");
  const { tokenData } = useAuth();

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 720px)").matches
  );
  const navigate = useNavigate();
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 720px)").addListener(handler);
  }, []);
  const splideRef = useRef(null);
  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);

  return (
    <div className={style.section}>
      <div className={style.packages_container}>
        <img src={wave_down} className={style.wave} alt={wave_down} />
        <SplitterHeader text="Be our success partner" />
        {/* <Header3
          dataAos="fade-up"
          classname={style.p}
          text="Flexible price packages that provide you with a variety of options and features"
        /> */}
         <Header3
          dataAos="fade-up"
          classname={style.p}
          text="Choose the type you want and join us now!"
        />
        <div className={style.buttons} >
          <Button
            className={Sort === "products" ? style.button_active : style.button}
            onClick={() => {
              setSort("products");
            }}
          >
            {t("Products")}
          </Button>
          <Button
            className={Sort === "services" ? style.button_active : style.button}
            onClick={() => {
              setSort("services");
            }}
          >
            {t("Services")}
          </Button>
        </div>
        {/* <div className={style.buttons}> */}
          <Button
            style={{
              color: "#88050d",
              background: "rgba(229, 188, 124, 1)",
              marginTop:"3rem",
              borderRadius:"5px",
            }}
            onClick={() => {
              window.open(
                `${SELLER_PORTAL}/register?step=main_info&tier=advanced&tierDuration=year&type=${
                  Sort === "products" ? "product" : "service"
                }&lang=${i18n?.language}`,
                "_blank"
              );
            }}
            id={style.subscribe_btn}
          >
            {t("Subscribe Now")}
          </Button>
        {/* </div> */}
        {/* <>
          <div className={style.buttons_container}>
            <div>
              <Radio.Group
                value={Type}
                onChange={(e) => setType(e.target.value)}
              >
                <Radio value="monthly" style={{ marginRight: "1rem" }}>
                  {" "}
                  {t("Monthly")}{" "}
                </Radio>
                <Radio value="annually"> {t("Annually")}</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className={style.flex_cards}>
            <Splide
              aria-label="My Favorite Images"
              id="pricing_page_splide"
              ref={splideRef}
              style={{ overflow: "hidden" }}
              options={{
                // type: 'loop',
                direction: i18n.language === "en" ? "ltr" : "rtl",
                // autoplay:true,
                // interval: 1500,  // Set autoplay speed to 2 seconds
                // speed: 1000,
                rewind: false,
                width: "100%",
                gap: "3rem",
                arrows: true,
                destroy: true,
                perPage: 3,
                focus: "center",
                padding: "20px",
                breakpoints: {
                  1280: {
                    destroy: false,
                    perPage: 2,
                    arrows: true,
                    padding: "10px",
                    gap: "2rem",
                  },
                  1000: {
                    destroy: false,
                    perPage: 2,
                    arrows: true,
                    padding: "10px",
                  },
                  700: {
                    destroy: false,
                    perPage: 1,
                    arrows: true,
                    padding: "50px",
                    gap: "2.5rem",
                  },
                },
                //lazyLoad: 'nearby',
                //drag   : 'free',
                //type:'loop',
              }}
            >
              {Packages[`${Sort}`].map((items, key) => {
                return (
                  <>
                    {items.name !== "lifetime" && (
                      <SplideSlide>
                        <div style={{ display: "block", position: "relative" }}>
                          <div
                            className={style.card}
                            style={{
                              border:
                                (tokenData &&
                                  tokenData?.[
                                    Sort === "products"
                                      ? "mostPopularPlanInProducts"
                                      : "mostPopularPlanInServices"
                                  ]) === items.name
                                  ? "3px solid #88050d"
                                  : "solid 1px grey",
                            }}
                          >
                            {(tokenData &&
                              tokenData?.[
                                Sort === "products"
                                  ? "mostPopularPlanInProducts"
                                  : "mostPopularPlanInServices"
                              ]) === items.name && (
                              <div className={style.popular}>
                                <BsFillBookmarkStarFill
                                  className={style.popular_icon}
                                />
                                {t("Popular")}
                              </div>
                            )}
                            <h1
                              className={style.card_h1}
                              style={{
                                color:
                                  (tokenData &&
                                    tokenData?.[
                                      Sort === "products"
                                        ? "mostPopularPlanInProducts"
                                        : "mostPopularPlanInServices"
                                    ]) === items.name
                                    ? "#88050d"
                                    : "black",
                              }}
                            >
                              {t(
                                `${
                                  items.name === "pro"
                                    ? "professional"
                                    : items?.name
                                }`
                              )}
                            </h1>
                            <div
                              className={style.card_p}
                              style={{
                                color:
                                  (tokenData &&
                                    tokenData?.[
                                      Sort === "products"
                                        ? "mostPopularPlanInProducts"
                                        : "mostPopularPlanInServices"
                                    ]) === items.name
                                    ? "#88050d"
                                    : "black",
                              }}
                            >
                              {t(`${items.p}`)}
                            </div>
                            <div className={style.pricing_content}>
                              <div
                                className={style.main_price}
                                style={{
                                  color:
                                    (tokenData &&
                                      tokenData?.[
                                        Sort === "products"
                                          ? "mostPopularPlanInProducts"
                                          : "mostPopularPlanInServices"
                                      ]) === items.name
                                      ? "#88050d"
                                      : "rgba(0, 128, 0, 1)",
                                }}
                              >
                                <span style={{ fontWeight: "bolder" }}>
                                  {convertToArabicNumeral(
                                    Type === "monthly"
                                      ? items.price_month
                                      : items.price_year
                                  )}{" "}
                                  {t("AED")}
                                </span>
                                <span className={style.month}>
                                  /
                                  {Type === "monthly"
                                    ? t("month")
                                    : t("yearly")}
                                </span>
                              </div>
                              <div
                                className={style.commisions_content}
                                style={{
                                  direction:
                                    i18n.language === "en" ? "rtl" : "ltr",
                                }}
                              >
                                <div className={style.commisions_content_li}>
                                  <p
                                    className={style.commisions_content_li_p}
                                    style={{
                                      background:
                                        (tokenData &&
                                          tokenData?.[
                                            Sort === "products"
                                              ? "mostPopularPlanInProducts"
                                              : "mostPopularPlanInServices"
                                          ]) === items.name
                                          ? "#88050d"
                                          : "",
                                    }}
                                  >
                                    {t("Commisions")}
                                  </p>
                                  <p
                                    style={{
                                      color: "#88050d",
                                      direction: "ltr",
                                    }}
                                    className={
                                      style.commisions_content_li_price
                                    }
                                  >
                                    {t(items?.commisions)}
                                  </p>
                                </div>
                                <div className={style.commisions_content_li}>
                                  <p
                                    className={style.commisions_content_li_p}
                                    style={{
                                      background:
                                        (tokenData &&
                                          tokenData?.[
                                            Sort === "products"
                                              ? "mostPopularPlanInProducts"
                                              : "mostPopularPlanInServices"
                                          ]) === items.name
                                          ? "#88050d"
                                          : "",
                                    }}
                                  >
                                    {t("Fixed payment")}
                                  </p>
                                  <p
                                    style={{ color: "rgb(80 59 28)" }}
                                    className={
                                      style.commisions_content_li_price
                                    }
                                  >
                                    {t(items?.fixedPayment)}
                                  </p>
                                </div>
                              </div>
                              <CustomButton
                                style={{
                                  background:
                                    (tokenData &&
                                      tokenData?.[
                                        Sort === "products"
                                          ? "mostPopularPlanInProducts"
                                          : "mostPopularPlanInServices"
                                      ]) === items.name
                                      ? "#88050d"
                                      : "",
                                }}
                                onClick={() => {
                                  window.open(
                                    `${SELLER_PORTAL}/register?step=main_info&tier=${
                                      items?.name
                                    }&tierDuration=${
                                      Type !== "annually" ? "month" : "year"
                                    }&type=${
                                      Sort === "products"
                                        ? "product"
                                        : "service"
                                    }&lang=${i18n?.language}`,
                                    "_blank"
                                  );
                                }}
                                id={style.subscribe_btn}
                                text={"Subscribe Now"}
                              />
                            </div>
                            <div className={`${style.features_container}`}>
                              <h2 className={style.h2}>{t("Subscription")}</h2>
                              {items.features.map((item, index) => (
                                <>
                                  <div className={style.feature_li}>
                                    {item.status ? (
                                      <CheckOutlined
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <CloseOutlined
                                        style={{ color: "#88050d" }}
                                      />
                                    )}
                                    {t(`${item.name}`)}
                                  </div>
                                </>
                              ))}
                              <h2 className={style.h2}>{t("Marketing")}</h2>
                              {items.marketing.map((item, index) => (
                                <>
                                  <div className={style.feature_li}>
                                    {item.status ? (
                                      <CheckOutlined
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <CloseOutlined
                                        style={{ color: "#88050d" }}
                                      />
                                    )}
                                    {t(`${item.name}`)}
                                  </div>
                                </>
                              ))}
                            </div>
                            <Button
                              onClick={() => {
                                navigate("/be%20a%20partner");
                              }}
                              className={style.Card_Btn}
                            >
                              {t("View All Details")}
                              <FaPlus />
                            </Button>
                          </div>
                        </div>
                      </SplideSlide>
                    )}
                  </>
                );
              })}
            </Splide>
          </div>
          {tokenData && tokenData?.isLifeTimePlanOn && (
            //----------------------- Life plan--------------------------
            <>
              {Packages[`${Sort}`].map((items, key) => {
                return (
                  <div className={style.single_card}>
                    {items.name === "lifetime" && (
                      <div
                        className={style.card}
                        id={items?.name === "lifetime" ? style.Best : ""}
                        style={{
                          border:
                            (tokenData &&
                              tokenData?.[
                                Sort === "products"
                                  ? "mostPopularPlanInProducts"
                                  : "mostPopularPlanInServices"
                              ]) === items.name
                              ? "3px solid #88050d"
                              : "",
                        }}
                      >
                        {(tokenData &&
                          tokenData?.[
                            Sort === "products"
                              ? "mostPopularPlanInProducts"
                              : "mostPopularPlanInServices"
                          ]) === items.name && (
                          <div className={style.popular}>
                            <BsFillBookmarkStarFill
                              className={style.popular_icon}
                            />
                            {t("Popular")}
                          </div>
                        )}
                        <h1
                          className={style.card_h1}
                          style={{
                            color:
                              items?.name === "lifetime" ? "#88050d" : "green",
                          }}
                        >
                          {t(`${items.name}`)}
                        </h1>
                        <div className={style.card_p}>{t(`${items.p}`)}</div>
                        <div className={style.pricing_content}>
                          <div className={style.main_price}>
                            <span
                              style={{
                                fontWeight: "bolder",
                                color:
                                  items?.name === "lifetime"
                                    ? "#88050d"
                                    : "green",
                              }}
                            >
                              {convertToArabicNumeral(items.price_year)}{" "}
                              {t("AED")}
                            </span>
                            <span
                              style={{
                                fontWeight: "bolder",
                                color:
                                  items?.name === "lifetime"
                                    ? "#88050d"
                                    : "green",
                              }}
                              className={style.month}
                            >
                              /
                              {items?.name === "lifetime"
                                ? t("lifetime")
                                : t("yearly")}
                            </span>
                          </div>
                          <div
                            className={style.commisions_content}
                            style={{
                              direction: i18n.language === "en" ? "rtl" : "ltr",
                            }}
                          >
                            <div className={style.commisions_content_li}>
                              <p className={style.commisions_content_li_p}>
                                {t("Commisions")}
                              </p>
                              <p
                                style={{ color: "#88050d", direction: "ltr" }}
                                className={style.commisions_content_li_price}
                              >
                                {t(items?.commisions)}
                              </p>
                            </div>
                            <div className={style.commisions_content_li}>
                              <p className={style.commisions_content_li_p}>
                                {t("Fixed payment")}
                              </p>
                              <p
                                style={{ color: "rgb(80 59 28)" }}
                                className={style.commisions_content_li_price}
                              >
                                {t(items?.fixedPayment)}
                              </p>
                            </div>
                          </div>
                          <CustomButton
                            style={{
                              background:
                                (tokenData &&
                                  tokenData?.[
                                    Sort === "products"
                                      ? "mostPopularPlanInProducts"
                                      : "mostPopularPlanInServices"
                                  ]) === items.name
                                  ? "#88050d"
                                  : "",
                            }}
                            onClick={() => {
                              window.open(
                                `${SELLER_PORTAL}/register?step=main_info&tier=${
                                  items?.name
                                }&tierDuration=${
                                  Type !== "annually" ? "month" : "year"
                                }&type=${
                                  Sort === "products" ? "product" : "service"
                                }&lang=${i18n?.language}`,
                                "_blank"
                              );
                            }}
                            id={style.subscribe_btn}
                            text={"Subscribe Now"}
                          />
                        </div>
                        <div className={`${style.features_container}`}>
                          <h2 className={style.h2}>{t("Subscription")}</h2>
                          {items.features.map((item, index) => (
                            <>
                              <div className={style.feature_li}>
                                {item.status ? (
                                  <CheckOutlined style={{ color: "green" }} />
                                ) : (
                                  <CloseOutlined style={{ color: "#88050d" }} />
                                )}
                                {t(`${item.name}`)}
                              </div>
                            </>
                          ))}
                          <h2 className={style.h2}>{t("Marketing")}</h2>
                          {items.marketing.map((item, index) => (
                            <>
                              <div className={style.feature_li}>
                                {item.status ? (
                                  <CheckOutlined style={{ color: "green" }} />
                                ) : (
                                  <CloseOutlined style={{ color: "#88050d" }} />
                                )}
                                {t(`${item.name}`)}
                              </div>
                            </>
                          ))}
                        </div>
                        <Button
                          onClick={() => {
                            navigate("/be%20a%20partner");
                          }}
                          className={style.Card_Btn}
                        >
                          {t("View All Details")}
                          <FaPlus />
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </> */}
        <img className={style.wave} src={wave_up} alt="wave" />
      </div>
    </div>
  );
}
