const isTest = window.location.hostname !== 'sona3.ae';
export const API_URL = isTest
  ? 'https://sona3-test-3918329fd6a7.herokuapp.com/api/v1'
  : 'https://sona3-dev-063c58a7c396.herokuapp.com/api/v1';
export const SELLER_PORTAL = isTest
  ? 'https://sonna3-seller.vercel.app'
  : 'https://seller.sona3.ae';
export const SHOP_PORTAL = isTest
  ? 'https://customer-web-flame.vercel.app'
  : 'https://shop.sona3.ae';
